import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import PageTitle from "../common/PageTitle";
import { Button, Grid, TextField, Typography } from "@mui/material";
import { useMutation } from "react-query";
import {
  nedcoBuyVoucherApiCaller,
  nedcoEnquiryApiCaller,
} from "shared/endPoints/apiCallers";
import Notiflix from "notiflix";
import { intlTranselate } from "shared/utils/ppUtils";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import translate from "i18n/translate";
import ShareModal from "components/profile/ShareModal";
import ApiRequest from "../../actions/ApiRequest";
import LoadingButton from "@mui/lab/LoadingButton";
import { useHistory } from "react-router-dom";

const propTypes = {};

const translations = {
  ar: {
    account_number: "رقم الحساب",
    desired_amount: "المبلغ",
    electric_company: "شركة الكهرباء",
    meter_number: "رقم المشترك",
    name: "اسم المشترك ",
    tarif_description: "نوع الحساب",
    less_than_21: "على الاقل 21 شيقل",
    value: "القيمه",
    search: "بحث",
    "searching...": "جاري البحث ...",
    Error: "خطأ",
    "Returned MSG": "رسالة الخطأ",
    "Amount Due": "المبلغ المستحق",
    "Remaining Amount": "المبلغ المتبقي",
    voucher_number: "رقم الفاتورة",
    no_of_kw: "عدد الكيلوات",
    Confirm: "تأكيد",
  },
  en: {
    account_number: "Account Number",
    desired_amount: "Desired Amount",
    electric_company: "Electric Company",
    meter_number: "Meter Number",
    name: "Name",
    tarif_description: "Tarif Description",
    less_than_21: "At least 21 NIS",
    value: "Value",
    search: "Search",
    "searching...": "Searching ...",
    Error: "Error",
    "Returned MSG": "Returned MSG",
    "Amount Due": "Amount Due",
    "Remaining Amount": "Remaining Amount",
    voucher_number: "Voucher Number",
    no_of_kw: "No of KW",
    Confirm: "Confirm",
  },
  he: {
    account_number: "מספר חשבון",
    desired_amount: "כמות מבוקשת",
    electric_company: "חברת חשמל",
    meter_number: "מספר מונה",
    name: "שם",
    tarif_description: "תיאור תעריף",
    less_than_21: "לפחות 21 שקל",
    value: "ערך",
    search: "חיפוש",
    "searching...": "מחפש ...",
    "Remaining Amount": "הסכום הנותר",
    "Amount Due": "סכום לתשלום",
    "Returned MSG": "הודעה שחזרה",
    Error: "שגיאה",
    voucher_number: "Voucher Number",
    no_of_kw: "מספר קו",
    Confirm: "אשר",
  },
};

export default function Nedco(props) {
  const [showConfirm, setShowConfirm] = useState(false);
  const [formData, setFormData] = useState({ amount: 0, sNumber: "" });
  const [userInformation, setUserInformation] = useState({});
  const [amountIsValid, setAmountIsValid] = useState(true);
  const [printedData, setPrintedData] = useState({});
  const [reviewData, setReviewData] = useState({});
  const [shareModalVisible, setShareModalVisible] = useState(false);
  const [showOthersButtons, setShowOthersButtons] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [nedcoBuyData, setNedcoBuyData] = useState(null);
  const [isError, setIsError] = useState(false);
  const locale = localStorage.langCity;
  const history = useHistory();

  useEffect(() => {
    const { state } = history.location;

    if (state?.number) {
      setFormData({ amount: 0, sNumber: state.number });
    }
  }, []);

  const {
    mutateAsync: nedcoEnquiry,
    isLoading,
    data,
  } = useMutation(nedcoEnquiryApiCaller, {
    onSuccess: (res) => {
      if (res?.status === "failed") {
        Notiflix.Notify.failure(res?.reason || "Something went wrong");
        return;
      }
      const dataResponse = res?.data;
      // console.log("dataResponse", dataResponse);
      if ("Error" in dataResponse) {
        setIsError(true);
      } else {
        setIsError(false);
      }
      const sortedData = {};
      Object.keys(dataResponse)
        .sort()
        .forEach((key) => {
          sortedData[translations[locale][key] || key] = dataResponse[key];
        });
      setUserInformation(sortedData);
      setShowConfirm(true);
    },
    onError: () => {
      Notiflix.Notify.failure(`Something went wrong !`, "", "Close");
    },
  });

  // const {
  //   mutateAsync: callBuyVoucher,
  //   isLoading: isBuying,
  //   data: nedcoBuyData,
  // } = useMutation(nedcoBuyVoucherApiCaller, {
  //   onSuccess: (res) => {
  //     if (res?.status === "failed") {
  //       Notiflix.Notify.failure(res?.reason || "Something went wrong");
  //       return;
  //     }
  //     console.log("res", res);
  //     Notiflix.Notify.success(res?.reason || "Ssuccess");
  //     const mergedBasicData = Object.assign({}, ...res.data.Basic_data);
  //     const mergedExtraData = Object.assign({}, ...res.data.extra_data);

  //     const combinedObject = { ...mergedBasicData, ...mergedExtraData };

  //     setPrintedData(combinedObject);
  //     setReviewData(mergedBasicData);
  //     setShowOthersButtons(true);
  //   },
  //   onError: () => {
  //     Notiflix.Notify.failure(`Something went wrong !`, "", "Close");
  //   },
  // });
  const onSubmit = (formData) => {
    setShowConfirm(false);
  };
  const callBuyVoucherApi = async (form) => {
    setIsLoading1(true);
    try {
      const res = await ApiRequest.post(
        `/nedco_buy_voucher?s_number=${form.sNumber}&amount=${form.amount}`
      );
      if (res?.status === "failed") {
        Notiflix.Notify.failure(res?.reason || "Something went wrong");
        return;
      }
      const basicData = res.data.Basic_data;
      const extraData = res.data.extra_data;
      const mergedBasicData = Object.assign({}, ...basicData);
      const mergedExtraData = Object.assign({}, ...extraData);

      const updatedData = {
        ...userInformation,
      };
      const voucherNumber = enforceLTR(
        mergedExtraData["voucher_number"]
      ).replace(/\*/g, "");
      updatedData[translations[locale]["voucher_number"]] = voucherNumber;
      updatedData[translations[locale]["no_of_kw"]] =
        mergedExtraData["no_of_kw"];

      setNedcoBuyData(updatedData);

      const combinedObject = { ...mergedBasicData, ...mergedExtraData };
      combinedObject["voucher_number"] = voucherNumber;
      const stringifiedData =
        "\n" +
        Object.entries(combinedObject)
          .map(([key, value]) => `${key}: ${value}`)
          .join("\n") +
        "\n";
      setReviewData(stringifiedData);
      setPrintedData(combinedObject);
      setShowOthersButtons(true);
    } catch (e) {
      Notiflix.Notify.failure(`Something went wrong !`, "", "Close");
    } finally {
      setIsLoading1(false);
    }
  };

  function printData(obj) {
    // Convert object to a printable HTML format
    let printableContent =
      '<div style="font-size: 16px; padding: 15px; margin-bottom: 10px;">';

    for (let key in obj) {
      // Check if obj[key] is equal to formData.sNumber
      if (key == "voucher_number") {
        const voucherNumber = enforceLTR(obj[key]).replace(/\*/g, "");
        printableContent += `<div style="border: solid 1px; padding: 5px 15px;"><strong>${key}: ${voucherNumber}</strong></div>`;
      } else {
        printableContent += `<span>${key}</span>: ${obj[key]}<br/>`;
      }
    }
    printableContent += "</div>";

    // Use a new window to open the printable content
    const printWindow = window.open("", "_blank");

    printWindow.document.open();
    printWindow.document.write(printableContent);
    printWindow.document.close();

    // Call the print method on the new window
    printWindow.print();
  }

  const renderDialog = () => {
    return (
      <Dialog open={showConfirm} onClose={() => setShowConfirm(false)}>
        <DialogTitle id="alert-dialog-title">هل انت متاكد؟</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Grid container justifyContent={"center"}>
              {Object.entries(userInformation || {}).map(([key, val], i) => {
                return (
                  <Grid
                    item
                    xs={12}
                    md={12}
                    container
                    justifyContent={"center"}
                    key={i}
                  >
                    <Typography color="black">{key + " : " + val}</Typography>
                  </Grid>
                );
              })}
              {printedData["voucher_number"] && (
                <Grid item xs={12} md={12} container justifyContent={"center"}>
                  <div style={{ padding: "10px 15px", border: "solid 1px" }}>
                    <Typography color="black">
                      {translations[locale]["voucher_number"] +
                        " : " +
                        printedData["voucher_number"]}
                    </Typography>
                  </div>
                </Grid>
              )}
              {printedData["no_of_kw"] && (
                <Grid item xs={12} md={12} container justifyContent={"center"}>
                  <Typography color="black">
                    {translations[locale]["no_of_kw"] +
                      " : " +
                      printedData["no_of_kw"]}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setShowConfirm(false);
              setShowOthersButtons(false);
            }}
          >
            إغلاق
          </Button>
          {!showOthersButtons && (
            <LoadingButton
              variant="contained"
              onClick={() => {
                if (!!nedcoBuyData) {
                  setShowConfirm(false);
                } else {
                  callBuyVoucherApi(formData);
                }
              }}
              loading={isLoading1}
              disabled={isError}
            >
              {translations[locale]["Confirm"]}
            </LoadingButton>
            // <Button
            //   variant="contained"
            //   onClick={() => {
            //     if (!!nedcoBuyData) {
            //       setShowConfirm(false);
            //     } else {
            //       callBuyVoucherApi(formData);
            //     }
            //   }}
            //   autoFocus
            // >
            //   {isLoading1 ? "...تأكيد" : "تأكيد"}
            // </Button>
          )}
          {showOthersButtons && (
            <div style={{ display: "flex" }}>
              <Button
                onClick={() => setShareModalVisible(true)}
                variant="contained"
                style={{ margin: 10, height: 40, width: 100 }}
              >
                مشاركة
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  printData(printedData);
                }}
                style={{ margin: 10, height: 40, width: 100 }}
              >
                طباعة
              </Button>
            </div>
          )}
        </DialogActions>
      </Dialog>
    );
  };
  function enforceLTR(str) {
    return `\u202A${str}\u202C`;
  }

  return (
    <React.Fragment>
      {renderDialog()}
      <ShareModal
        shareModal={shareModalVisible}
        setShareModal={setShareModalVisible}
        contentMessage={reviewData}
      />
      <PageTitle title={translate("Nedco")} backPage="/pay_bills">
        <form
          id="nedco_form"
          onSubmit={(e) => {
            e.preventDefault();
            const amount = e.target.amount.value;
            const sNumber = e.target.sNumber.value;

            if (Number(amount) < 21) {
              setAmountIsValid(false);
              return;
            }
            setAmountIsValid(true);
            setFormData({ amount, sNumber });
            nedcoEnquiry({ sNumber, amount });
          }}
        >
          <img
            src="https://images.phoneplay.me/logo_location/nedco.jpg"
            alt="nedco"
            className="nedco-image"
            style={{ width: 250, height: 150 }}
          />
          <Grid pt={2} container width="100%" spacing={3}>
            <Grid item xs={12} container justifyContent={"center"}>
              <Grid item xs={6}>
                <TextField
                  required
                  name="sNumber"
                  fullWidth
                  label={translations[locale]["meter_number"]}
                  onChange={(e) =>
                    setFormData({ ...formData, sNumber: e.target.value })
                  }
                  value={formData.sNumber}
                  autoFocus
                />
              </Grid>
            </Grid>
            <Grid item xs={12} container justifyContent={"center"}>
              <Grid item xs={6}>
                <TextField
                  required
                  fullWidth
                  inputProps={{
                    min: 21,
                  }}
                  error={!amountIsValid}
                  helperText={
                    !amountIsValid && translations[locale]["less_than_21"]
                  }
                  name="amount"
                  placeholder={translations[locale]["desired_amount"]}
                  label={translations[locale]["value"]}
                />
              </Grid>
            </Grid>
            <Grid item container justifyContent={"center"}>
              <LoadingButton
                variant="contained"
                type="submit"
                loading={isLoading}
              >
                {translations[locale]["search"]}
              </LoadingButton>
              {/* <Button disabled={isLoading} variant="contained" type="submit">
                {isLoading
                  ? translations[locale]["searching..."]
                  : translations[locale]["search"]}
              </Button> */}
            </Grid>
          </Grid>
        </form>
      </PageTitle>
    </React.Fragment>
  );
}

Nedco.propTypes = propTypes;
