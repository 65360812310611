import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import translate from "../../../i18n/translate";
import { chargeJawwal } from "../../../actions/companiesAction";
import Badge from "../../ui/Badge/Badge";
import JawwalPlusCard from "./JawwalPlusCard";
import { isEmpty, isNil, set } from "lodash";
import Button from "@mui/material/Button";
import { IS_MOBILE_DISPLAY } from "shared/constants/constants";
import { noBalanceContext } from "../../../reducers/no_balance";
import { useContext } from "react";
import { getLastTransaction } from "actions/reportsAction";
import { useDispatch, useSelector } from "react-redux";
import { setNumberChanged } from "../../../actions/companiesAction";
import BoltIcon from "@mui/icons-material/Bolt";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import Spinner from "components/ui/spinner/Spinner";
import { useIsRtl } from "shared/hooks/ppHooks";
import CircularProgress from "@mui/material/CircularProgress";
import { toast } from "react-hot-toast";
import { ShowToastPromise } from "components/common/ShowToastPromise";
import ConfirmModal from "../shared/InfoModal";

const Selected = ({
  min,
  setMin,
  g3,
  setg3,
  credit,
  setCredit,
  setPlus,
  plus,
  setRom,
  rom,
  chargeJawwal,
  isDarkTheme,
}) => {
  const noBalanceCtx = useContext(noBalanceContext);
  const history = useHistory().location.pathname;
  const pushHistory = useHistory();
  const [loadingSpinner, isLoading] = useState(false);
  const [openInfoModel, setOpenInfoModel] = useState(false);
  const [chargeType, setChargeType] = useState();
  const isRtL = useIsRtl();
  const dispatch = useDispatch();
  const pathname = history.split("/");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const selectedCards = [min, g3, credit, rom, plus];
  const numberFromQuery = queryParams.get("number") || "";
  const numberIsChanged = useSelector(
    (state) => state.companies.numberIsChanged
  );
  const [quickProcessLoading, setQuickProcessLoading] = useState(false);
  useEffect(() => {
    if (numberIsChanged) {
      dispatch(setNumberChanged(false));
      !credit && clearSelected();
    }
  }, [numberIsChanged]);

  const onCreditRemove = () => {
    sessionStorage.removeItem("JawwalCredit");
    setCredit("");
  };
  const remove3g = () => {
    sessionStorage.removeItem("Jawwal3g");
    setg3("");
  };
  const removeMin = () => {
    sessionStorage.removeItem("JawwalMin");
    setMin("");
  };

  const removeRom = () => {
    sessionStorage.removeItem("JawwalRom");
    setRom("");
  };

  const removePlus = () => {
    sessionStorage.removeItem("JawwalPlus");
    setPlus("");
  };

  const closeToast = (toastId) => {
    toast.dismiss(toastId); // Dismiss the specific toast
  };

  const charge = async (type) => {
    const check = await chargeJawwal(
      {
        jawwal3g: g3 || null,
        jawwalRom: rom || null,
        jawwalCredit: credit || null,
        jawwalMin: min || null,
        jawwalPlus: plus || null,
      },
      history,
      pushHistory,
      true
    );
    if (check === "noBalance") {
      noBalanceCtx.openCardHandler();
      if (type === "quickly") {
        throw new Error("quickly");
      }
    }
  };

  const onClickTypeCredit = async (e, type) => {
    e.preventDefault();
    queryParams.delete("number");
    if (type !== "quickly") {
      isLoading(true);
      await charge(type);
      pushHistory.push(`/`);
      getLastTransaction("0", 5);
      clearSelected();
      isLoading(false);
    } else {
      setQuickProcessLoading(true);
      ShowToastPromise(async () => await charge(type), {
        mobileNumber,
      });

      if (pathname.length > 1) {
        pathname.pop();
      }
      setQuickProcessLoading(false);
      clearSelected();
      const newPath = pathname.join("/");
      pushHistory.push(`${newPath}/undefined`);
    }
  };
  const clearSelected = () => {
    onCreditRemove();
    removeMin();
    remove3g();
    removeRom();
    removePlus();
  };
  const mobileNumber = pathname?.[pathname.length - 1];
  const disabledButton =
    !/^\d{10}$/.test(mobileNumber) ||
    (numberFromQuery && mobileNumber !== numberFromQuery) ||
    loadingSpinner ||
    (credit?.price ? parseFloat(credit?.price) : 0) +
      (rom?.price ? parseFloat(rom?.price) : 0) +
      (g3?.price ? parseFloat(g3?.price) : 0) +
      (plus?.amount ? parseFloat(plus?.amount) : 0) +
      (min?.price ? parseFloat(min?.amount) : 0) ===
      0;

  const totalAmount =
    (credit?.price ? parseFloat(credit?.price) : 0) +
    (rom?.price ? parseFloat(rom?.price) : 0) +
    (g3?.price ? parseFloat(g3?.price) : 0) +
    (min?.price ? parseFloat(min?.price) : 0) +
    (plus?.amount ? parseFloat(plus?.amount) : 0);

  return (
    <div className="row">
      {loadingSpinner && <Spinner />}
      <div
        className={IS_MOBILE_DISPLAY ? "col-12" : "col-9"}
        style={{
          margin: "0 auto",
        }}
      >
        <div
          className={`card m-4s fixed-top1 position-sticky mt-2 ${
            isDarkTheme && "dark-background"
          }`}
          style={{
            padding: "10px 0 30px",
            minHeight: IS_MOBILE_DISPLAY ? "auto" : 170,
          }}
        >
          <div className="row mt-1 fixed-topx px-3">
            {IS_MOBILE_DISPLAY && (
              <div className="mt-2">
                <div
                  className={` ${isDarkTheme && "dark-background"}`}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "0 10px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "60%",
                    }}
                  >
                    <h5
                      className="text-muted mt-1 mb-2"
                      title="Balance"
                      style={{ fontSize: "1.2rem" }}
                    >
                      {translate("total")}:{" "}
                    </h5>
                    <h3 className="text-info">₪ {totalAmount}</h3>
                  </div>
                  <div>
                    <Button
                      type="submit"
                      disabled={disabledButton}
                      onClick={() => {
                        setOpenInfoModel(true);
                      }}
                      // title="accept"
                      variant="contained"
                      color="success"
                      startIcon={
                        <HourglassTopIcon sx={isRtL ? { mr: 1, ml: 1 } : {}} />
                      }
                      fullWidth
                      sx={{ marginBottom: 0.5 }}
                    >
                      {translate("accept")}
                    </Button>
                    <Button
                      type="submit"
                      disabled={disabledButton}
                      onClick={() => {
                        setChargeType("quickly");
                        setOpenInfoModel(true);
                      }}
                      variant="contained"
                      color="warning"
                      startIcon={
                        quickProcessLoading ? (
                          <CircularProgress size={24} sx={{ color: "white" }} />
                        ) : (
                          <BoltIcon sx={isRtL ? { mr: 1, ml: 1 } : {}} />
                        )
                      }
                      fullWidth
                    >
                      {quickProcessLoading ? null : translate("quickly")}
                    </Button>
                  </div>
                </div>
              </div>
            )}
            {!isNil(credit) && !isEmpty(credit) && (
              <div className="col-lg-3 col-md-4 col-sm-4 mt-3">
                <div className="card">
                  <div className="frame1">
                    <img
                      alt="Jawwal Credit"
                      src={
                        credit.url ||
                        "https://res.cloudinary.com/dtu4lltbk/image/upload/v1622203339/eced7efa-a16b-4fdd-9528-2c1f10356e1c_lzfhei.jpg"
                      }
                      width="260px"
                      height="100px"
                    ></img>
                    {credit.flexiblePrice && (
                      <label className="text-abs">{credit.price}</label>
                    )}
                    <a href className="close-btn" onClick={onCreditRemove}>
                      <i className="fa fa-times" aria-hidden="true"></i>
                    </a>
                  </div>
                </div>
              </div>
            )}
            {!isNil(min) && !isEmpty(min) && (
              <div className="col-lg-3 col-md-4 col-sm-4 mt-3">
                <div className="card">
                  <div className="frame1">
                    <img
                      alt="Jawwal Min"
                      src={min.url}
                      width="260px"
                      height="100px"
                    ></img>
                    {(min.renew === "True" || min.renew === "true") && (
                      <Badge text={translate("Renewable")}></Badge>
                    )}
                    <a href className="close-btn" onClick={removeMin}>
                      <i className="fa fa-times" aria-hidden="true"></i>
                    </a>
                  </div>
                </div>
              </div>
            )}
            {!isNil(g3) && !isEmpty(g3) && (
              <div className="col-lg-3 col-md-4 col-sm-4 mt-3">
                <div className="card">
                  <div className="frame1">
                    <img
                      alt="Jawwal 3G"
                      src={g3.url}
                      width="260px"
                      height="100px"
                    ></img>
                    {(g3.renew === "True" || g3.renew === "true") && (
                      <Badge text={translate("Renewable")}></Badge>
                    )}
                    <a href className="close-btn">
                      <i
                        className="fa fa-times"
                        aria-hidden="true"
                        onClick={remove3g}
                      ></i>
                    </a>
                  </div>
                </div>
              </div>
            )}
            {!isNil(rom) && !isEmpty(rom) && (
              <div className="col-lg-3 col-md-4 col-sm-4 mt-3">
                <div className="card">
                  <div className="frame1">
                    <img
                      alt="Jawwal Rom"
                      src={rom.url}
                      width="260px"
                      height="100px"
                    ></img>
                    <a href className="close-btn" onClick={removeRom}>
                      <i className="fa fa-times" aria-hidden="true"></i>
                    </a>
                  </div>
                </div>
              </div>
            )}
            {!isNil(plus) && !isEmpty(plus) && (
              <div className="col-lg-3 col-md-4 col-sm-4 mt-3">
                <JawwalPlusCard bundle={plus} selected={true}>
                  <a href className="close-btn" onClick={removePlus}>
                    <i className="fa fa-times" aria-hidden="true"></i>
                  </a>
                </JawwalPlusCard>
              </div>
            )}
          </div>
        </div>
      </div>
      {!IS_MOBILE_DISPLAY && (
        <div className="col-3">
          <div className="card total-balance-card mt-2">
            <div
              className={`card-body p-2 ${isDarkTheme && "dark-background"}`}
            >
              <h5
                className="text-muted mt-1 mb-2"
                title="Balance"
                style={{ fontSize: "1.2rem" }}
              >
                {translate("total")}
              </h5>
              <h3 className="text-info mt-2">
                ₪{" "}
                {(credit?.price ? parseFloat(credit?.price) : 0) +
                  (rom?.price ? parseFloat(rom?.price) : 0) +
                  (g3?.price ? parseFloat(g3?.price) : 0) +
                  (min?.price ? parseFloat(min?.price) : 0) +
                  (plus?.amount ? parseFloat(plus?.amount) : 0)}
              </h3>

              <Button
                type="submit"
                disabled={disabledButton}
                onClick={() => {
                  setOpenInfoModel(true);
                }}
                // title="accept"
                variant="contained"
                color="success"
                startIcon={
                  <HourglassTopIcon sx={isRtL ? { mr: 1, ml: 1 } : {}} />
                }
                fullWidth
                sx={{ marginBottom: 0.5 }}
              >
                {translate("accept")}
              </Button>
              <Button
                type="submit"
                disabled={disabledButton}
                onClick={(e) => {
                  setChargeType("quickly");
                  setOpenInfoModel(true);
                }}
                variant="contained"
                color="warning"
                startIcon={
                  quickProcessLoading ? (
                    <CircularProgress size={24} sx={{ color: "white" }} />
                  ) : (
                    <BoltIcon sx={isRtL ? { mr: 1, ml: 1 } : {}} />
                  )
                }
                fullWidth
              >
                {quickProcessLoading ? null : translate("quickly")}
              </Button>
            </div>
          </div>
        </div>
      )}
      <ConfirmModal
        phoneNumber={mobileNumber}
        selectedCards={selectedCards}
        open={openInfoModel}
        handleConfirm={(e) => {
          setOpenInfoModel(false);
          onClickTypeCredit(e, chargeType);
        }}
        handleClose={() => setOpenInfoModel(false)}
        totalAmount={totalAmount}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  isDarkTheme: state.darkTheme.isDarktheme,
});

export default connect(mapStateToProps, { chargeJawwal })(Selected);
