import React, { useState } from "react";
import {
  TextField,
  Button,
  FormControl,
  Grid,
  Box,
  CircularProgress,
} from "@mui/material";
import ApiRequest from "../../../actions/ApiRequest";
import Toast from "components/common/Toast";
const ArabBank = () => {
  const [newCapatcha, setNewCapatcha] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newCapatcha.trim().length == 0) {
      return;
    }
    setIsLoading(true);

    // Loop through each setting in the payload to check if it changed
    try {
      // If a setting has changed, send an update request for that setting
      const res = await ApiRequest.post(`set_abc?cap=${newCapatcha}`);
      if (res.status === 200) setIsChanged(true);
      else throw new Error("Something went wrong");
    } catch (error) {
      Toast.fire({
        title: "Something Went Wrong!",
        icon: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (!isLoading && isChanged) {
    Toast.fire({
      title: "Settings Updated Successfully!",
      icon: "success",
    });
    setIsChanged(false);
  }

  return (
    <Box
      sx={{
        margin: "20px auto",
        padding: "30px",
        // "linear-gradient(45deg, #0081C5 70%,#E7E9E8 15%,#DECED8 15% )",
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
        borderRadius: "10px",
        // color: "#fff",
        width: "100%",
        // height: "100%",
      }}
    >
      <h1>Please Enter Code: </h1>
      {isLoading && (
        <Box
          sx={{ display: "flex", position: "fixed", left: "50%", top: "40%" }}
        >
          <CircularProgress />
        </Box>
      )}

      <Grid
        container
        spacing={4}
        mt={3}
        sx={{ display: "flex", alignItems: "center" }}
      >
        <Grid item xs={12} sm={12}>
          <form onSubmit={handleSubmit}>
            <Grid item container xs={12} sm={12} spacing={4}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <TextField
                    label="Arab Bank Captcha"
                    value={newCapatcha}
                    onChange={(e) => setNewCapatcha(e.target.value)}
                    required
                    autoFocus
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={4}>
                <Button
                  variant="contained"
                  type="submit"
                  sx={{
                    mt: 2,
                    padding: "8px 16px",
                    width: "150px",
                    height: "55px",
                  }}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Box>
  );
};
export default ArabBank;
