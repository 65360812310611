import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import EPRSProducts from "./EPRSProducts"; // New Component
import EPRSProviders from "./EPRSProviders"; // New Component
import translate from "i18n/translate";
import { connect } from "react-redux";

const EPRSSettings = ({ isDarktheme }) => {
  const tableStyle = !isDarktheme
    ? { width: "400px", marginTop: "100px", border: "2px solid black" }
    : { width: "400px", marginTop: "100px", border: "2px solid white" };

  return (
    <div>
      <TableContainer>
        <Table
          style={tableStyle}
          sx={{ minWidth: 650, margin: "26px auto" }}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              <TableCell
                className={!isDarktheme ? "" : "text-light"}
                align="center"
              >
                {translate("EPRS")}
              </TableCell>
              <TableCell
                className={!isDarktheme ? "" : "text-light"}
                align="center"
              >
                {translate("Update")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell
                className={!isDarktheme ? "" : "text-light"}
                align="center"
              >
                <EPRSProducts value={0} />
              </TableCell>
              <TableCell
                className={!isDarktheme ? "" : "text-light"}
                align="center"
              >
                <EPRSProducts value={1} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                className={!isDarktheme ? "" : "text-light"}
                align="center"
              >
                <EPRSProviders value={0} />
              </TableCell>
              <TableCell
                className={!isDarktheme ? "" : "text-light"}
                align="center"
              >
                <EPRSProviders value={1} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

const mapStateToProps = ({ darkTheme: { isDarktheme } }) => ({
  isDarktheme,
});

export default connect(mapStateToProps, null)(EPRSSettings);
