import React, { useEffect } from "react";
import CelltopLogo from "../../assests/images/logo/celltop.svg";
// Maps hostname to title and favicon
const META_MAP = {
  "phoneplay.me": {
    title: "Phone Play",
    favicon: "/black-logo.png", // Path to PhonePlay favicon
  },
  "celltop.vip": {
    title: "Cell Top",
    favicon: CelltopLogo, // Path to CellTop favicon
  },
};

const DefaultMeta = {
  title: "Phone Play",
  favicon: "/black-logo.png", // Path to PhonePlay favicon
};

const getMeta = () => {
  const hostname = window.location.hostname;
  return META_MAP[hostname] || DefaultMeta;
};

const DynamicMeta = () => {
  useEffect(() => {
    const { title, favicon } = getMeta();

    // Update the document title
    document.title = title;

    // Update the favicon
    const link = document.querySelector("link[rel~='icon']");
    if (link) {
      link.href = favicon;
    } else {
      const newLink = document.createElement("link");
      newLink.rel = "icon";
      newLink.href = favicon;
      document.head.appendChild(newLink);
    }
  }, []);

  return null;
};

export default DynamicMeta;
