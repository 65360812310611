import axios from "axios";
import Notiflix from "notiflix";

// Determine the base URL dynamically based on the hostname
const hostname = window.location.hostname;

// Map hostname to the corresponding base URL
const BASE_URL_MAP = {
  "phoneplay.me": "https://api.phoneplay.me/api/v1/resources/",
  "celltop.vip": "https://api.celltop.vip/api/v1/resources/",
};

// Default to phoneplay if hostname is not explicitly mapped
export const BASE_URL = BASE_URL_MAP[hostname] || BASE_URL_MAP["phoneplay.me"];

// Retrieve the IP from localStorage
export const IP = localStorage.getItem("ip");

// Define default headers
export const DEFAULT_HEADERS = {
  "Access-Control-Allow-Orgin": "http://localhost:8080", // Local dev origin
  IP: IP,
};

axios.interceptors.response.use(
  function (config) {
    if (
      config.data?.reason?.includes?.("token") &&
      config.data?.reason?.includes?.("expired") &&
      !window.location.href.includes?.("/signin")
    ) {
      // getIP()
      localStorage.removeItem("jwtUserToken");
      localStorage.removeItem("companies");
      localStorage.removeItem("token exp");
      window.location.href = "/signin?token-expired";
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.request.use(
  (config) => {
    // use config.params if it has been set
    config.params = config.params || {};
    // add any client instance specific params to config
    config.params["dl"] = localStorage.langCity ?? "en";

    const token = localStorage.jwtUserToken;

    if (token) {
      axios.defaults.headers["token"] = token;
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    // Do something with response error
    Notiflix.Notify.failure("Something went Wrong - " + error?.message);
    return Promise.reject(error);
  }
);

axios.defaults.baseURL = BASE_URL;

axios.defaults.headers = {
  ...axios.defaults.headers,
  ...DEFAULT_HEADERS,
};

window.axios = axios;

export default axios;
