import React, { useState, useEffect } from "react";
import translate from "../../i18n/translate";
import TextFieldGroup from "../common/TextFieldGroup";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { loginUser, logoutUser } from "../../actions/userAction";
import validateLoginInput from "../../validation/validateLoginInput";
import Message from "../common/Message";
import Spinner from "../ui/spinner/Spinner";

import ReCAPTCHA from "reaptcha";
import LanguageChooser from "../ui/Settings/Language/LanguageChooser";
//import { onMessageListener } from "../../firebase";
import Notifications from "../common/Notification";
import DynamicLogo from "shared/components/DynamicLogo";
// import Notiflix from "notiflix";

const SignIn = ({
  loginUser,
  isAuthenticated,
  massage,
  loading,
  isDarktheme,
}) => {
  const history = useHistory();
  const intl = useIntl();
  const [ip, setIp] = useState(null);
  const [notToken, setNotToken] = useState("");
  // const [show, setShow] = useState(false)
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = "Sign In | Phone Play";
    if (!localStorage.getItem("langCity")) {
      localStorage.setItem("langCity", "ar");
    }
    if (isAuthenticated && history.location.search !== "?token-expired") {
      history.push("/");
    } else if (history.location.search === "?token-expired") {
      dispatch(logoutUser(history));
    }

    // commented because of bug
    // const getIP = async () => {
    //   const { IPv4 } = await (await fetch('https://geolocation-db.com/json/')).json()
    //   // localStorage.setItem('ip', IPv4)
    //   setIp(IPv4)
    // }
    // getIP()
    window.onpopstate = () =>
      setTimeout(() => {
        history.go(1);
      }, 0);

    if (
      parseInt(localStorage.getItem("errorCount")) &&
      parseInt(localStorage.getItem("errorCount")) !== errorCount
    ) {
      setErrorCount(parseInt(localStorage.getItem("errorCount")));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getNewToken = (token) => {
    console.log("token15515", token);
    setNotToken(token);
  };

  const [loginForm, setLoginForm] = useState({
    userName: "",
    password: "",
  });
  const [errors1, setErrors1] = useState({});
  const [errorCount, setErrorCount] = useState(0);

  let passwordChanged = history.location?.state?.password_changed;

  const onChange = (e) => {
    setLoginForm({ ...loginForm, [e.target.name]: e.target.value });
  };
  const onSubmit = (e) => {
    e.preventDefault();
    const { errors, isValid } = validateLoginInput(loginForm);
    if (!isValid) {
      setErrors1(errors);
    } else {
      loginUser({ ...loginForm, notToken }, ip, history, errorCount).finally(
        () => {
          setErrorCount(errorCount + 1);
          // localStorage.setItem('errorCount', errorCount + 1)
        }
      );
    }
  };
  function verfiy() {
    setErrorCount(0);
    localStorage.setItem("errorCount", 0);
  }

  return (
    <section className={!isDarktheme ? "auth signin" : "auth dark-signin"}>
      <Notifications setNotToken={getNewToken} />
      <div className="container">
        <div className="row justify-content-md-center signIn-d">
          <div className="card-wrapper">
            <div className="card fat">
              <div className="card-body">
                <div className="brand">
                  <DynamicLogo />
                </div>
                <h4 className="card-title text-center">
                  {translate("Sign in to your account")}
                </h4>

                {massage !== null &&
                  massage !== "" &&
                  massage !== undefined && <Message msg={massage} />}
                {passwordChanged && !massage && (
                  <Message
                    msg={intl.formatMessage({
                      id: "Your password changed successfully, you can login now with the new password",
                    })}
                    type="success"
                  />
                )}

                <form
                  method="POST"
                  className="login-validation"
                  noValidate=""
                  onSubmit={(e) => onSubmit(e)}
                >
                  <TextFieldGroup
                    style={{ width: "100%" }}
                    className="mb-5"
                    placeholder={intl.formatMessage({
                      id: "Enter your username",
                    })}
                    name="userName"
                    type="number"
                    value={loginForm.userName}
                    onChange={onChange}
                    error={errors1.userName}
                    autoFocus={true}
                    label={intl.formatMessage({ id: "Username" })}
                    required={true}
                  />

                  <TextFieldGroup
                    style={{ width: "100%" }}
                    placeholder={intl.formatMessage({ id: "Password" })}
                    name="password"
                    type="password"
                    value={loginForm.password}
                    onChange={onChange}
                    error={errors1.password}
                    label={intl.formatMessage({ id: "Password" })}
                    link={{
                      url: "/forgot-password",
                      text: translate("Forgot Password?"),
                    }}
                    required={true}
                  />
                  {errorCount < 3 && (
                    <div className="form-group mb-0 mt-4 actions">
                      <button
                        type="submit"
                        className="btn btn-primary btn-block"
                        disabled={loading}
                      >
                        {translate("Sign in")}
                      </button>
                    </div>
                  )}
                  {errorCount >= 3 && (
                    <div className="form-group mb-0 mt-4 actions">
                      <ReCAPTCHA
                        sitekey="6LdXOFoqAAAAAEC_xXmgwuhqbZru24F3F85fVFSS"
                        onVerify={verfiy}
                      />
                    </div>
                  )}
                  <LanguageChooser />
                </form>
              </div>
            </div>
            <div className="mt-4 text-center">
              {translate("Don't have an account?")}{" "}
              <a href="/NumberVerification">{translate("Sign Up")}</a>
            </div>
            <div className="text-center" style={{ textAlign: "center" }}>
              0.0.7 V
            </div>
          </div>
        </div>
      </div>
      {loading && <Spinner />}
    </section>
  );
};
const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  massage: state.error.massage,
  loading: state.auth.userDetailsLoading,
  isDarktheme: state.darkTheme.isDarktheme,
});
export default connect(mapStateToProps, { loginUser })(SignIn);
