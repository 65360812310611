import React, { useState, useEffect } from "react";
import translate from "../../i18n/translate";
import TextFieldGroup from "../common/TextFieldGroup";
import Select from "../common/Select";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { signUpUser, callGetSellerNumber } from "./../../actions/userAction";
import "./auth.css";
import validateSignUpInput from "../../validation/validateSignUpInput";
import Spinner from "../ui/spinner/Spinner";

import { CITIES } from "./cities";
import axios from "axios";
import { Checkbox } from "@mui/material";
import {
  USER_TYPES,
  USER_TYPES_WITH_ADMIN,
} from "../../shared/constants/constants";
import { isArray } from "lodash";
import { formatSellerOptionsForSelect } from "shared/utils/formatUtils";
import { BASE_URL, DEFAULT_HEADERS } from "actions/ApiRequest";
import Settings from "components/ui/Settings/Settings";
import DynamicLogo from "shared/components/DynamicLogo";

const countries = [
  { value: "palestine", label: "Palestine" },
  { value: "israel", label: "Israel" },
];

const SignUp = ({ isAuthenticated, signUpUser, loading, isDarktheme }) => {
  const history = useHistory();
  let phoneNumber = history.location.state?.mobile;
  let RefCode = history.location.state?.refCode;

  const [ip, setIp] = useState("N/A");
  const [cities, setSities] = useState([]);
  const [enableforeign, setEnableforeign] = useState(false);

  useEffect(() => {
    document.title = "Sign up | Phone Play";
    if (isAuthenticated) {
      history.push("/");
    }
    // if (!ip) {
    //   callIpApi()
    //   .then((info) => {
    //     setIp(info.ip);
    //   });
    // }

    // commented because of CORS
    // const getIP = async () => {
    //   const res = await axios.get("https://geolocation-db.com/json/", null);
    //   const myIp = res.data.IPv4;
    //   localStorage.setItem("ip", myIp);
    //   setIp(myIp);
    // };
    // getIP();
    callGetSellerNumber().then((res) => {
      setSignUpForm({ ...signUpForm, username: res.data, mobile: phoneNumber });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [signUpForm, setSignUpForm] = useState({
    username: "",
    fullName: "",
    email: "",
    mobile: "",
    password: "",
    password2: "",
    address: "",
    country: "",
    city: "",
    reseller_id: "",
    user_type: "",
    code: RefCode,
  });
  const [errors1, setErrors1] = useState({});
  const [resellerOptions, setResellerOptions] = useState([]);
  const intl = useIntl();

  const onChange = (e) => {
    if (e.target.name === "country" && e.target.value === "null") {
      return;
    }
    const newValue = {
      [e.target.name]: e.target.value,
    };
    if (e.target.name === "country") {
      setSities(CITIES[e.target.value]);
      newValue["city"] = "";
    }
    setSignUpForm({ ...signUpForm, ...newValue });
  };
  const onSubmit = (e) => {
    e.preventDefault();

    const { errors, isValid } = validateSignUpInput(signUpForm);
    if (!isValid) {
      setErrors1(errors);
    } else {
      const form = { ...signUpForm };
      if (!form.reseller_id || !form.reseller_id?.length)
        form.reseller_id = "100000";

      signUpUser(signUpForm, ip, history, enableforeign);
    }
  };

  useEffect(() => {
    try {
      fetch(BASE_URL + "get_allseller?reseller_id=reseller", {
        headers: DEFAULT_HEADERS,
        method: "POST",
      })
        .then((response) => response.json())
        .then(({ all_sellers }) => {
          if (isArray(all_sellers)) {
            const filtered = all_sellers.filter(
              (x) =>
                x.type === USER_TYPES_WITH_ADMIN.admin.value ||
                x.type === USER_TYPES_WITH_ADMIN.reseller.value
            );
            setResellerOptions(formatSellerOptionsForSelect(filtered, true));
          }
        });
    } catch (e) {
      console.log(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Settings isAuthenticated={false} />
      <section className={!isDarktheme ? "auth signup" : "auth dark-signup"}>
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="card-wrapper">
              <div className="card fat">
                <div className="card-body">
                  <div className="brand">
                    <DynamicLogo />{" "}
                  </div>
                  <h4 className="card-title text-center">
                    {translate("Create your free account")}
                  </h4>
                  <h6 className="card-subtitle text-center">
                    {translate("Already have an account?")}{" "}
                    <a href="/signin">{translate("Sign in")}</a>
                  </h6>

                  <form
                    method="POST"
                    className="signup-validation"
                    noValidate=""
                    onSubmit={(e) => onSubmit(e)}
                  >
                    <TextFieldGroup
                      placeholder={intl.formatMessage({
                        id: "Creating a username ...",
                      })}
                      name="username"
                      type="text"
                      value={signUpForm.username}
                      label={translate("Username")}
                      disable={true}
                    />

                    <TextFieldGroup
                      placeholder={intl.formatMessage({
                        id: "Enter your fullname",
                      })}
                      name="fullName"
                      type="text"
                      value={signUpForm.fullName}
                      onChange={onChange}
                      error={errors1.fullName}
                      autoFocus={true}
                      label={translate("Fullname")}
                      required={true}
                    />

                    <TextFieldGroup
                      placeholder={intl.formatMessage({
                        id: "Enter your mobile number",
                      })}
                      name="mobile"
                      type="number"
                      value={phoneNumber}
                      error={errors1.mobile}
                      label={translate("Mobile Number")}
                      disable={true}
                    />
                    <Select
                      name="user_type"
                      placeholder={intl.formatMessage({
                        id: "Select your profision",
                      })}
                      label={intl.formatMessage({
                        id: "Select your profision",
                      })}
                      options={Object.values(USER_TYPES)}
                      error={errors1.user_type}
                      value={signUpForm.user_type}
                      required={true}
                      onChange={onChange}
                    />
                    <Select
                      name="reseller_id"
                      placeholder={intl.formatMessage({
                        id: "select_reseller",
                      })}
                      label={intl.formatMessage({ id: "reseller" })}
                      options={resellerOptions}
                      value={signUpForm?.reseller_id || ""}
                      onChange={onChange}
                    />
                    <Select
                      name="country"
                      placeholder={intl.formatMessage({
                        id: "Select a country",
                      })}
                      label={intl.formatMessage({ id: "Enter your country" })}
                      options={countries}
                      error={errors1.country}
                      value={signUpForm.country}
                      required={true}
                      onChange={onChange}
                    />

                    <Select
                      name="city"
                      placeholder={intl.formatMessage({
                        id: "Enter your city",
                      })}
                      label={intl.formatMessage({ id: "City" })}
                      options={cities}
                      error={errors1.city}
                      value={signUpForm.city}
                      required={true}
                      onChange={onChange}
                    />

                    <TextFieldGroup
                      placeholder={intl.formatMessage({
                        id: "Enter your address",
                      })}
                      name="address"
                      type="text"
                      value={signUpForm.address}
                      onChange={onChange}
                      error={errors1.address}
                      label={translate("Address")}
                    />

                    <TextFieldGroup
                      placeholder={intl.formatMessage({ id: "Password" })}
                      name="password"
                      type="password"
                      value={signUpForm.password}
                      onChange={onChange}
                      error={errors1.password}
                      label={translate("Password")}
                      required={true}
                    />

                    <TextFieldGroup
                      placeholder={intl.formatMessage({
                        id: "Confirm password",
                      })}
                      name="password2"
                      type="password"
                      value={signUpForm.password2}
                      onChange={onChange}
                      error={errors1.password2}
                      label={translate("Confirm password")}
                      required={true}
                    />
                    <TextFieldGroup
                      placeholder={intl.formatMessage({
                        id: "Enter your email",
                      })}
                      name="email"
                      type="email"
                      value={signUpForm.email}
                      onChange={onChange}
                      error={errors1.email}
                      label={translate("Email")}
                    />

                    {RefCode && (
                      <TextFieldGroup
                        placeholder={intl.formatMessage({
                          id: "Enter Rererral Code",
                        })}
                        name="code"
                        type="text"
                        value={RefCode}
                        error={errors1.code}
                        label={translate("Rererral Code")}
                        disable={true}
                      />
                    )}

                    {!RefCode && (
                      <TextFieldGroup
                        placeholder={intl.formatMessage({
                          id: "Enter Rererral Code",
                        })}
                        name="code"
                        type="text"
                        value={signUpForm.code}
                        onChange={onChange}
                        error={errors1.code}
                        label={translate("Rererral Code")}
                      />
                    )}

                    <Checkbox
                      onClick={() => {
                        setEnableforeign(!enableforeign);
                      }}
                      checked={enableforeign}
                    />

                    <label style={{ color: !isDarktheme ? "" : "white" }}>
                      {translate("Enable Foreign")}
                    </label>

                    <div className="form-group mb-0 mt-4 actions">
                      <button
                        type="submit"
                        className="btn btn-primary btn-block"
                        disabled={loading}
                      >
                        {translate("Register")}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {loading && <Spinner />}
      </section>
    </>
  );
};
const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.auth.userDetailsLoading,
  isDarktheme: state.darkTheme.isDarktheme,
});
export default connect(mapStateToProps, { signUpUser })(SignUp);
