import {
  Box,
  TextField,
  IconButton,
  Button,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  Grid,
  Select,
  MenuItem,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import ApiRequest from "../../../actions/ApiRequest";
import Toast from "components/common/Toast";
import RefreshIcon from "@mui/icons-material/Refresh";
import translate from "i18n/translate";

const useStyles = makeStyles((theme) => ({
  textField: {
    minWidth: 300,
    marginRight: 1,
  },
  button: {
    marginLeft: 1,
  },
}));

const EditAccount = ({
  desc,
  variable,
  aKey,
  checkbox = false,
  carrier,
  userName,
  onUpdateBalance,
  isAccountActive = true,
  fullWidth = false,
  readOnly,
  weight,
  index,
  selectOptions = [],
  updateSettings = false,
}) => {
  const classes = useStyles();
  const [isEditing, setIsEditing] = useState(false);
  const [originalValue, setOriginalValue] = useState("");
  const [changeText, setChangeText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsLoading(true);
    const value =
      aKey === "busy"
        ? event.target.checked
          ? "1"
          : "0"
        : event.target.checked;
    sendApiRequest(value);
  };

  useEffect(() => {
    setOriginalValue(variable);
    setChangeText(variable);
    setIsChecked(variable);
  }, [variable]);

  const handleEditClick = () => {
    setIsEditing(true);
    setChangeText(originalValue);
  };

  const handleSaveClick = () => {
    console.log("handleSaveClick", changeText, originalValue);
    setIsEditing(false);
    if (changeText === originalValue || changeText.trim().length === 0) return;
    setIsLoading(true);
    sendApiRequest(changeText);
  };

  const sendApiRequest = async (text) => {
    const editTopupUrl = `edit_topup_account?operation=edit&carrier=${carrier}&user_name=${userName}&required_value=${aKey}&new_value=${text}`;
    const editSettingsUrl = `update_settings?index=${index}&new_value=${text}&carrier=${carrier}&key=${aKey}`;

    try {
      const res = await ApiRequest.post(
        updateSettings ? editSettingsUrl : editTopupUrl
      );
      if (res.data?.status?.toLowerCase() === "failed") {
        throw new Error(res.data.reason);
      }
      setOriginalValue(changeText);
      setIsChecked(!isChecked);
      Toast.fire({
        title: "Settings Updated Successfully!",
        icon: "success",
      });
    } catch (err) {
      Toast.fire({
        title: err.message,
        icon: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancelClick = () => {
    setIsEditing(false);
  };

  const handleChange = (event) => {
    setChangeText(event.target.value);
  };

  return (
    <Grid item xs={12} sm={checkbox ? 6 : 12} md={checkbox ? 4 : 12}>
      {isLoading && (
        <Box
          sx={{ display: "flex", position: "fixed", left: "50%", top: "40%" }}
        >
          <CircularProgress />
        </Box>
      )}
      {checkbox && (
        <FormControlLabel
          control={
            <Checkbox
              checked={isChecked}
              onChange={handleCheckboxChange}
              color="primary"
            />
          }
          style={{ color: isAccountActive ? "black" : "red" }}
          label={desc}
        />
      )}
      {!checkbox && (
        <h6>
          {isEditing ? (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <span
                style={{
                  color: isAccountActive ? "black" : "red",
                }}
              >
                {desc}:{" "}
              </span>
              {selectOptions.length > 0 ? (
                <Select
                  value={changeText}
                  onChange={handleChange}
                  className={classes.textField}
                  variant="outlined"
                  size="small"
                  fullWidth={fullWidth}
                >
                  {selectOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              ) : (
                <TextField
                  value={changeText}
                  onChange={handleChange}
                  className={classes.textField}
                  variant="outlined"
                  size="small"
                  fullWidth={fullWidth}
                />
              )}
              <Button
                variant="contained"
                onClick={handleSaveClick}
                startIcon={<SaveIcon sx={{ ml: "2px", mr: "2px" }} />}
                sx={{ marginRight: 1 }}
              >
                {translate("save")}
              </Button>
              <Button
                variant="outlined"
                onClick={handleCancelClick}
                startIcon={<CancelIcon sx={{ ml: "2px", mr: "2px" }} />}
                color="error"
                sx={{ ml: "5px", mr: "5px" }}
              >
                {translate("cancel")}
              </Button>
            </Box>
          ) : (
            <React.Fragment>
              <span
                style={{
                  color: isAccountActive ? "black" : "red",
                  fontWeight: weight || "bold",
                }}
              >
                {desc}: {originalValue}
              </span>
              {!readOnly && (
                <IconButton onClick={handleEditClick} size="small">
                  <EditIcon />
                </IconButton>
              )}

              {aKey === "balance" && (
                <IconButton onClick={onUpdateBalance}>
                  <RefreshIcon />
                </IconButton>
              )}
            </React.Fragment>
          )}
        </h6>
      )}
    </Grid>
  );
};

export default EditAccount;
