import React, { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

const SettingsContainer = ({
  isDarktheme,
  tabLabels,
  tabComponents,
  componentId,
}) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `${componentId}-tab-${index}`,
      "aria-controls": `${componentId}-tabpanel-${index}`,
    };
  }

  return (
    <Box>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          className={!isDarktheme ? "SettingsTabs" : "dark-SettingsTabs"}
          value={value}
          onChange={handleChange}
          aria-label="tabs example"
        >
          {tabLabels.map((label, index) => (
            <Tab
              key={index}
              className={!isDarktheme ? "SettingsTab" : "dark-SettingsTab"}
              label={label}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </Box>
      <Box>
        {tabComponents.map((Component, index) => (
          <Box
            key={index}
            role="tabpanel"
            hidden={value !== index}
            id={`${componentId}-tabpanel-${index}`}
            aria-labelledby={`${componentId}-tab-${index}`}
          >
            {value === index && <Component />}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default SettingsContainer;
