import React from "react";
import { connect } from "react-redux";
import SettingsContainer from "./shared/SettingsContainer";
import EPRSSettings from "./EPRSSettings";
import CompanySettings from "../Companies/CompanySettings";
import translate from "../../../i18n/translate";

const EPRSSettingsContainer = ({ isDarktheme }) => {
  return (
    <SettingsContainer
      isDarktheme={isDarktheme}
      componentId="eprs"
      tabLabels={[translate("Update EPRS"), translate("EPRS Settings")]}
      tabComponents={[EPRSSettings, () => <CompanySettings companyId="EPRS" />]}
    />
  );
};

const mapStateToProps = ({ darkTheme: { isDarktheme } }) => ({
  isDarktheme,
});

export default connect(mapStateToProps, null)(EPRSSettingsContainer);
