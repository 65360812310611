import React from "react";
import { connect } from "react-redux";
import BlackLogo from "../../assests/images/logo/black-logo.svg";
import WhiteLogo from "../../assests/images/logo/white-logo.svg";
import CelltopLogo from "../../assests/images/logo/celltop.svg";

const SVG_MAP = {
  "phoneplay.me": {
    light: BlackLogo,
    dark: WhiteLogo,
  },
  "celltop.vip": {
    light: CelltopLogo,
    dark: CelltopLogo,
  },
};

const DefaultSVG = {
  light: BlackLogo,
  dark: WhiteLogo,
};

const getLogo = (isDarkTheme) => {
  const hostname = window.location.hostname;
  return (
    SVG_MAP[hostname]?.[isDarkTheme ? "dark" : "light"] ||
    DefaultSVG[isDarkTheme ? "dark" : "light"]
  );
};

const DynamicLogo = ({ isDarkTheme, width, height, className }) => {
  const logoPath = getLogo(isDarkTheme);

  return (
    <img
      src={logoPath}
      alt="Logo"
      width={width}
      height={height}
      className={className}
    />
  );
};
const mapStateToProps = (state) => ({
  isDarktheme: state.darkTheme.isDarktheme,
});
export default connect(mapStateToProps)(DynamicLogo);
