import React from "react";
import { connect } from "react-redux";
import SettingsContainer from "./shared/SettingsContainer";
import PeletalkSettings from "./PeletalkSettings";
import CompanySettings from "../Companies/CompanySettings";
import translate from "../../../i18n/translate";

const PeletalkSettingsContainer = ({ isDarktheme }) => {
  return (
    <SettingsContainer
      isDarktheme={isDarktheme}
      componentId="peletalk"
      tabLabels={[translate("Update Peletalk"), translate("Peletalk Settings")]}
      tabComponents={[
        PeletalkSettings,
        () => <CompanySettings companyId="Peletalk" />,
      ]}
    />
  );
};

const mapStateToProps = ({ darkTheme: { isDarktheme } }) => ({
  isDarktheme,
});

export default connect(mapStateToProps, null)(PeletalkSettingsContainer);
